import React, { useRef, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getSession } from '../api/SessionApi';

const JoinSessionInput = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [ invalidSessionId, setInvalidSessionId ] = useState(false);
    const inputRef = useRef(null);

    const joinSession = () => {
        const sessionId = inputRef.current.value;

        getSession(sessionId)
            .then(session => {
                if(session) {
                    history.push(`/session/${session.id}`);
                } else {
                    setInvalidSessionId(true);
                }
            })
            .catch(error => {
                console.log("Create session error", error);
                setInvalidSessionId(true);
            });
    };

    const onKeyPress = (e) => {
        if (event.keyCode === 13) {
            joinSession();
        }
    };

    const invalidSessionIdDiv = (
        <div className={"invalid-feedback"}>{t("invalid-session-id")}</div>
    );

    return (
        <div className={"input-group"}>
          <input onKeyPress={onKeyPress} ref={inputRef} type="text" className={`form-control ${invalidSessionId ? "is-invalid" : ""}`} placeholder={t("session-id")}/>
          <div className={"input-group-append"}>
            <button onClick={joinSession} className={"btn btn-primary"} type="button">{t("join-session")}</button>
          </div>
          { invalidSessionId ? invalidSessionIdDiv : null}
        </div>
    );
}

export default JoinSessionInput;