import React, { useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getValue, setValue } from "../util/LocalStorage";


const key = "cookieConsentShown";

const CookieConsentToast = () => {

    const toastShown = getValue(key, false);

    if(toastShown) {
       return null;
    }

    const { t } = useTranslation();
    const toastRef = useRef(null);
    let bootstrapToast;

    useEffect(() => {
        const options = {
            autohide: false
        };

        bootstrapToast = new bootstrap.Toast(toastRef.current, options);
        bootstrapToast.show();
    }, []);

    const saveConsent = () => {
        setValue(key, true);

        bootstrapToast.hide();
    };

    return (
        <div ref={toastRef} className={"toast cookie-consent"}>
            <div className={"toast-header"}>
                <strong className={"me-auto"}>{t("cookie-consent.title")}</strong>
                <button type="button" className={"btn-close"} data-bs-dismiss="toast" onClick={saveConsent}></button>
            </div>
            <div className={"toast-body"}>
                {t("cookie-consent.content")}
            </div>
        </div>
    );
}

export default CookieConsentToast;