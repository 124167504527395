import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Logo = () => {
    const { t } = useTranslation();

    return (
        <h1 className={"text-center"}>
            <Link to={"/"} className={"text-decoration-none text-body"}>{t("app.title")}</Link>
        </h1>
    );
}

export default Logo;