import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";

import { useShakeListener } from "../hook/ShakeListener";

const ShakeableDiceCup = ({onShake}) => {
    const { t } = useTranslation();
    const diceRollerRef = useRef(null);

    if(onShake) {
        useShakeListener(onShake);
    }

    // Draggable dice roller
    let lastPositionX = 0
    let lastPositionY = 0;
    let lastPositionDiffX = 0
    let lastPositionDiffY = 0;

    const drag = (positionX, positionY) => {
        const diffX = lastPositionX - positionX;
        const diffY = lastPositionY - positionY;

        const element = diceRollerRef.current;

        // Update image position
        element.style.top = (element.offsetTop - diffY) + "px";
        element.style.left = (element.offsetLeft - diffX) + "px";

        // Check for on shake event
        if(onShake && lastPositionDiffY > 0 && diffY < 0) {
            onShake();
        }

        // Update last values
        lastPositionX = positionX;
        lastPositionY = positionY;
        lastPositionDiffX = diffX
        lastPositionDiffY = diffY;
    };

    // Desktop / mouse click events
    const onMouseMove = (e) => {
        drag(e.clientX, e.clientY);
    };

    const onMouseUp = (e) => {
        e.preventDefault();

        // Unregister event listeners
        document.onmouseup = null;
        document.onmousemove = null;

        // Reset dice cup position
        const element = diceRollerRef.current;

        element.style.removeProperty("top");
        element.style.removeProperty("left");
    };

    const onMouseDown = (e) => {
        e.preventDefault();

        // Set initial position
        lastPositionX = e.clientX;
        lastPositionY = e.clientY;

        // Register event listeners
        document.onmousemove = onMouseMove;
        document.onmouseup = onMouseUp;
    };

    // Mobile / touch events
    let touchIdentifier;

    const findTouchEvent = (touchList, identifier) => {
        for(let i = 0; i < touchList.length; i++) {
            const touchEvent = touchList.item(i);

            if(touchEvent.identifier === identifier) {
                return touchEvent;
            };
        };

        return null;
    }

    const onTouchMove = (e) => {

        const touchEvent = findTouchEvent(e.changedTouches, touchIdentifier);

        if(!touchEvent) {
            return;
        }

        drag(touchEvent.clientX, touchEvent.clientY);
    };

    const onTouchEnd = (e) => {

        const touchEvent = findTouchEvent(e.changedTouches, touchIdentifier);

        if(!touchEvent) {
            return;
        }

        // Unregister event listeners
        document.ontouchmove = null;
        document.ontouchend = null;

        // Unset the touch identifier to listen to
        touchIdentifier = null;

        // Reset dice cup position
        const element = diceRollerRef.current;

        element.style.removeProperty("top");
        element.style.removeProperty("left");
    };

    const onTouchStart = (e) => {

        if(touchIdentifier) {
            return;
        }

        const touch = e.changedTouches[0];

        // Set the touch identifier to listen to
        touchIdentifier = touch.identifier;

        // Set initial position
        lastPositionX = touch.clientX;
        lastPositionY = touch.clientY;

        // Register event listeners
        document.ontouchmove = onTouchMove;
        document.ontouchend = onTouchEnd;
    };

    // Test
    const [className, setClassName] = useState("");

    const runAnimation = () => {
        setClassName("");

        setTimeout(() => setClassName("single-dice-cup-shake"), 100);
    };

    useEffect(() => {
        setTimeout(runAnimation, 2000);
        setTimeout(runAnimation, 2250);
        setTimeout(runAnimation, 5000);
    }, []);


    return (
        <>
            <img
                ref={diceRollerRef}
                src={"/image/dice-cup-2.png"}
                className={`dice-cup ${className}`}
                onMouseDown={ onShake ? onMouseDown : null }
                onTouchStart={ onShake ? onTouchStart : null }
            />
        </>
    );
};

export default ShakeableDiceCup;