const extend = (session) => {
    if(session.players && session.myPlayerId) {
        session.myPlayer = session.players.find(p => p.id === session.myPlayerId);

        if(!session.myPlayer) {
            console.error("Session reducer :: My player is undefined", session);
        }
    }

    return session;
}

const reducer = (previousSession, action) => {
    console.debug("SessionReducer :: Processing action", action);

    switch(action.type) {
        case "clear":
            return {};
        case "update":
            return extend({
                ...previousSession,
                ...action.session
            });
        case "setDiceRoll":
            return extend({
                ...previousSession,
                myDiceRoll: action.diceRoll
            });
        default:
            console.error("SessionReducer :: Unknown action type", action.type);
            return previousSession;
    }
};

export {
    reducer
};