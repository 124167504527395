import React, { createContext, useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import DiceRollResult from "../../component/DiceRollResult";
import AnnounceDiceInput from "../../component/AnnounceDiceInput";
import QuickLieButton from "../../component/QuickLieButton";

// Contexts
import SessionContext from "../../context/SessionContext";

// Sound
import { playSound } from "../../util/Sound";

import * as DiceRolls from "../../util/DiceRolls";

const generateQuickLieDiceRolls = (diceRoll) => {
    const suggestions = 3;

    const length = DiceRolls.list.length;
    const index = DiceRolls.indexOf(diceRoll);

    const suggestionIndexTo = Math.min(length - 1);
    const suggestionIndexFrom = Math.min(index + 1, suggestionIndexTo);
    const suggestionIndexDiff = suggestionIndexTo - suggestionIndexFrom;
    const suggestionIndexSteps = Math.max(1, Math.floor(suggestionIndexDiff / suggestions));


    const diceRolls = [];

    for(let i = 0; i < suggestions && i < suggestionIndexDiff; i++) {
        const suggestionIndex = suggestionIndexFrom + i * suggestionIndexSteps;

        if(suggestionIndex >= suggestionIndexTo) {
            // Suggested index is to high
            break;
        }

        diceRolls.push(DiceRolls.list[suggestionIndex]);
    }

    return diceRolls;
};

const QuickLieFragment = () => {
    const { t } = useTranslation();
    const { session, dispatch } = useContext(SessionContext);
    const { myPlayer, priorPlayer, myDiceRoll } = session;

    const quickLieReferenceDiceRoll = priorPlayer && priorPlayer.announcedDiceRoll ? DiceRolls.max(priorPlayer.announcedDiceRoll, myDiceRoll) : myDiceRoll;
    const quickLieDiceRolls = generateQuickLieDiceRolls(quickLieReferenceDiceRoll);

    if(quickLieDiceRolls.length === 0) {
        return null;
    }

    return (
        <>
            <h3 className={"text-center"}>{t("quick-lie")}</h3>
            <div className={"d-flex justify-content-center"}>
                <div className={"btn-group w-50"}>
                    {
                        quickLieDiceRolls.map((diceRoll, index) => (
                            <QuickLieButton key={index} diceRoll={diceRoll}/>
                        ))
                    }
                </div>
            </div>
        </>
    )
};

const AnnouncingFragment = () => {

    const { t } = useTranslation();
    const { session, dispatch } = useContext(SessionContext);
    const { myPlayer, priorPlayer, myDiceRoll } = session;

    useEffect(() => playSound("dice-drop-1.mp3"), []);

    return (
        <>
            <div className={"text-center h3"}>{t("announce-your-dice-roll")}</div>
            <div className={"py-3"}>
                <DiceRollResult diceRoll={myDiceRoll}/>
            </div>
            <div className={"d-grid py-3"}>
                <AnnounceDiceInput/>
            </div>
            <div className={"mt-5"}>
                <QuickLieFragment/>
            </div>
        </>
    );
};

export default AnnouncingFragment;