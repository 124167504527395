import { Client, Message } from '@stomp/stompjs';

class SessionClient {

    constructor(sessionId) {
        this.sessionId = sessionId;
    }

    activate(onConnect) {
        if(this.isActive()) {
            this.deactivate();
        }

        const protocol = location.protocol === "https:" ? "wss" : "ws";
        const clientOptions = {
            brokerURL: `${protocol}://${location.host}/stomp`,
            connectHeaders: {
                sessionId: this.sessionId
            }
            // debug: function (str) {
            //     console.log(str);
            // }
        };


        this.client = new Client(clientOptions);
        this.client.onConnect = onConnect;
        this.client.activate();

        console.debug("SessionClient :: Activated", this.sessionId);
    }

    deactivate() {
        this.client.deactivate();
        this.client = null;
        this.sessionId = null;
        console.debug("SessionClient :: Deactivated");
    }

    request(path, handler){
        const headers = { sessionId: this.sessionId };
        const destination = `/app/session/${this.sessionId}${path}`;
        console.debug(`SessionClient :: Requesting ${path} (${destination})`);

        const client = this.client;

        this.client.subscribe(
            destination,
            frame => {
                console.debug("SessionClient :: Request frame", frame)

                // Unsubscribe from destination
                client.unsubscribe(destination);

                // Pass response
                handler(JSON.parse(frame.body));
            },
            headers
        );
    }

    subscribe(topic, handler) {
        const headers = { sessionId: this.sessionId };
        const destination = `/topic/session/${this.sessionId}${topic}`;
        console.debug(`SessionClient :: Subscribing to topic ${topic} (${destination})`);
        return this.client.subscribe(destination, frame => handler(JSON.parse(frame.body)), headers);
    }

    publish(path, payload) {
        const destination = `/app/session/${this.sessionId}${path}`;

        const message = {
            destination: destination,
            body: JSON.stringify(payload),
            headers: {
                sessionId: this.sessionId
            }
        };

        this.client.publish(message);
        console.debug(`SessionClient :: Publishing message to path ${path} (${destination})`, payload);
    }

    isActive() {
        return this.client != null;
    }
}

export default SessionClient;