import React, { createContext, useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


// Components
import DiceRollResult from "../../component/DiceRollResult";
import DiceCup from "../../component/DiceCup";

// Contexts
import SessionContext from "../../context/SessionContext";

const WaitingForRollFragment = () => {

    const { t } = useTranslation();
    const { session, dispatch } = useContext(SessionContext);
    const { myPlayer, priorPlayer, currentPlayer } = session;

    return (
        <>
            <h3 className={"text-center"}>{t("player-x-is-deciding", { player: currentPlayer.name })}</h3>
            <h3 className={"text-center mt-5"}>{t("player-x-dice-roll", { player: priorPlayer.name })}</h3>
            <div className={"d-flex justify-content-center"}>
                <DiceRollResult diceRoll={session.priorPlayer.announcedDiceRoll}/>
            </div>
        </>
    );
};

export default WaitingForRollFragment;