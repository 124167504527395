import React, { createContext, useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


// Components
import DiceRollResult from "../../component/DiceRollResult";
import DiceCup from "../../component/DiceCup";

// Contexts
import SessionContext from "../../context/SessionContext";

// Sound
import { playSound } from "../../util/Sound";

const WaitingForRollFragment = () => {

    const { t } = useTranslation();
    const { session, dispatch } = useContext(SessionContext);
    const { myPlayer, priorPlayer, currentPlayer } = session;

    useEffect(() => playSound("dice-drop-1.mp3"), []);

    return (
        <>
            <h3 className={"text-center card-title"}>{t("player-x-is-announcing", { player: currentPlayer.name })}</h3>
            <div className={"d-flex justify-content-center align-items-center dice-cup-pane"}>
                <DiceCup initialAnimationClass={"dice-cup-drop-animation"} initialAnimationDuration={500}/>
            </div>
        </>
    );
};

export default WaitingForRollFragment;