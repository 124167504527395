import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import SessionContext from "../../context/SessionContext";

const InviteFriendsFragment = () => {
    const { t } = useTranslation();
    const { session } = useContext(SessionContext);

    const link = `${location.protocol}//${location.host}/session/${session.id}`;

    const supportsClipboardWriting = navigator.clipboard?.writeText !== undefined;

    const copySessionId = () => {
        navigator.clipboard.writeText(session.id);
    };

    const copyLink = () => {
        navigator.clipboard.writeText(link);
    };

    return (
        <>
            <h3 className={"text-center"}>{t("invite-friends")}</h3>
            <div className={"input-group"}>
                <span className={"input-group-text"}>{t("session-id")}</span>
                <input type="text" readOnly={true} className={"form-control"} value={session.id}/>
                {
                    supportsClipboardWriting ? (
                        <button className={"btn btn-primary"} type="button" onClick={copySessionId}>{t("copy")}</button>
                    ) : null
                }
            </div>
            <div className={"input-group input-group-sm mt-3"}>
                <span className={"input-group-text"}>{t("link")}</span>
                <input type="text" readOnly={true} className={"form-control"} value={link}/>
                {
                    supportsClipboardWriting ? (
                        <button className={"btn btn-primary"} type="button" onClick={copyLink}>{t("copy")}</button>
                    ) : null
                }
            </div>
        </>
    );
};

export default InviteFriendsFragment;

