import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import SessionContext from "../../context/SessionContext";

const RoundInfoFragment = () => {
    const { t } = useTranslation();
    const { session } = useContext(SessionContext);

    return (
        <>
            <h3 className={"text-center"}>{t("round")} {session.round}{session.rounds > 0 ? ("/" + session.rounds) : ""}</h3>
        </>
    );
};

export default RoundInfoFragment;

