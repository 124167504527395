import React, { useReducer, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import { language } from "./Translation";

import NavBar from './component/NavBar';
import Footer from './component/Footer';
import CookieConsentToast from './component/CookieConsentToast';
import ServiceWorkerInstaller from './component/ServiceWorkerInstaller';
import ToastContainer from './component/ToastContainer';
import SessionPageRouteComponent from './component/SessionPageRouteComponent';
import WelcomePage from './page/WelcomePage';
import ImprintPage from './page/ImprintPage';
import DonatePage from './page/DonatePage';
import PrivacyPolicyPage from './page/PrivacyPolicyPage';
import TermsOfServicePage from './page/TermsOfServicePage';

const App = () => {
    const { t } = useTranslation();

    return (
        <Router>
            <Helmet>
                <link rel="manifest" href={`/i18n/${language}/manifest.json`}/>
                <title>{t("title.welcome")}</title>
            </Helmet>
            <ToastContainer>
                <CookieConsentToast/>
                <ServiceWorkerInstaller/>
            </ToastContainer>
            <div className={"d-flex flex-column min-vh-100 background-image-wood-1"}>
                <Switch>
                    <Route exact path="/" component={WelcomePage}/>
                    <Route exact path="/donate" component={DonatePage}/>
                    <Route exact path="/imprint" component={ImprintPage}/>
                    <Route exact path="/privacy-policy" component={PrivacyPolicyPage}/>
                    <Route exact path="/terms-of-service" component={TermsOfServicePage}/>
                    <Route path="/session/:sessionId" component={SessionPageRouteComponent}/>
                    <Redirect to={"/"}/>
                </Switch>
                <Footer/>
            </div>
        </Router>
    );
}

export default App;