import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react";
import { Link, Redirect } from "react-router-dom";

// Contexts
import SessionContext from "../context/SessionContext";


// Components
import InGamePage from "../page/InGamePage";
import LobbyPage from "../page/LobbyPage";
import GameResultsPage from "../page/GameResultsPage";
import RoundResultsPage from "../page/RoundResultsPage";

import Spinner from "./Spinner";

// Switch page based on the session state
const switchSessionPage = (state) => {

    switch(state) {
        case "InGame":
            return (<InGamePage/>);
        case "Lobby":
            return (<LobbyPage/>);
        case "GameResults":
            return (<GameResultsPage/>);
        case "RoundResults":
            return (<RoundResultsPage/>);
        case "NonExisting":
            return (<Redirect to={"/"}/>);
        default:
            console.debug("Unknown state", state);
            return (
                <div className={"d-flex justify-content-center pt-3"}>
                    <Spinner/>
                </div>
            );
    }
};


const SessionPageSwitch = () => {
    const { session } = useContext(SessionContext);

    return switchSessionPage(session.state);
};

export default SessionPageSwitch;