import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();

    return (
        <footer className={"mt-auto p-2 d-flex justify-content-center"}>
            <Link to={"/donate"}><small>{t("donate")}</small></Link>
            <Link to={"/imprint"} className={"ms-2"}><small>{t("imprint")}</small></Link>
            <Link to={"/privacy-policy"} className={"ms-2"}><small>{t("privacy-policy")}</small></Link>
            <Link to={"/terms-of-service"} className={"ms-2"}><small>{t("terms-of-service")}</small></Link>
        </footer>
    );
}

export default Footer;