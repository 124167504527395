import React from 'react';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { createSession } from '../api/SessionApi';

const CreateSessionButton = () => {
    const { t } = useTranslation();
    const history = useHistory();

    const onClick = () => {
        createSession()
            .then(session => history.push(`/session/${session.id}`))
            .catch(error => console.log("Create session error", error))
    };

    return (
        <button onClick={onClick} className={"btn btn-lg btn-primary"} type="button">
            <span>{t("create-session")}</span>
        </button>
    );
};

export default CreateSessionButton;