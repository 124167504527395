import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import SessionContext from "../context/SessionContext";

import Header from "../component/Header";
import InGameFragmentSwitch from "../component/InGameFragmentSwitch";

import PlayerListFragment from "../fragment/in-game/PlayerListFragment";
import RoundInfoFragment from "../fragment/in-game/RoundInfoFragment";

const InGamePage = (props) => {

    const { t } = useTranslation();
    const { session } = useContext(SessionContext);
    const { round, rounds } = session;

    const roundInfoArguments = { round, rounds };
    const roundInfo = session.rounds > 0 ? t("round-x-of-x", roundInfoArguments) : t("round-x", roundInfoArguments);

    return (
        <>
            <Helmet>
                <title>{t("title.in-game")}</title>
            </Helmet>
            <div className={"container-fluid pt-3"}>
                <Header pageTitle={roundInfo}/>
                <div className={"row"}>
                    <div className={"col-xl pb-3"}>
                        <div className={"container-outside"}>
                        </div>
                    </div>
                    <div className={"col-xl pb-3"}>
                        <div className={"container-center"}>
                            <InGameFragmentSwitch/>
                        </div>
                    </div>
                    <div className={"col-xl pb-3"}>
                        <div className={"container-outside"}>
                            <PlayerListFragment/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InGamePage;