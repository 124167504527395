import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import CreateSessionButton from '../component/CreateSessionButton';
import JoinSessionInput from '../component/JoinSessionInput';

const WelcomePage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("title.welcome")}</title>
            </Helmet>
            <div className={"container-center pt-5"}>
                <h1 className={"text-center display-1"}>{t("app.title")}</h1>
                <h4 className={"text-center"}>{t("app.subtitle")}</h4>
                <div className={"d-grid gap-3 mt-5"}>
                    <CreateSessionButton/>
                    <JoinSessionInput/>
                </div>
            </div>
        </>
    );
};

export default WelcomePage;