import React from 'react';

const characterMap = {
    1: "\u2680",
    2: "\u2681",
    3: "\u2682",
    4: "\u2683",
    5: "\u2684",
    6: "\u2685"
};

const mapCharacter = (value) => {
    return characterMap[value];
};

const DiceRollResult = ({diceRoll}) => {

    if(!diceRoll) {
        return null;
    }

    return (
        <div className={"text-center display-2"}>
            <b>{mapCharacter(diceRoll.upperEye)}
            {mapCharacter(diceRoll.lowerEye)}</b><br/>
            {diceRoll.upperEye}{diceRoll.lowerEye}
        </div>
    );
};

export default DiceRollResult;