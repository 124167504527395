import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import SessionContext from "../../context/SessionContext";

const PlayerList = () => {
    const { t } = useTranslation();
    const { session } = useContext(SessionContext);
    const myPlayer = session.myPlayer;

    const listPlayers = session.players.map(
        (player) => (
            <li key={player.id} className={`list-group-item d-flex justify-content-between ${session.currentPlayer.id === player.id ? "list-group-item-primary" : ""}`}>
                <span>
                    {player.name}
                    {player.id === session.myPlayer.id ? <span className={"ms-1 badge bg-primary"}>{t("me")}</span> : null}
                    {player.state === "Rolling" ? " 🎲" : null}
                    {player.state === "Announcing" ? " 📣" : null}
                    {player.state === "Deciding" ? " 🤔" : null}
                </span>
                <span>
                    {`${player.losses} 💀`}
                </span>
            </li>)
    );

    return (
        <ul className={"list-group"}>
            { listPlayers }
        </ul>
    );
};

const PlayerListFragment = () => {
    const { t } = useTranslation();

    return (
        <>
            <h3 className={"text-center"}>{t("players")}</h3>
            <PlayerList/>
        </>
    );
};

export default PlayerListFragment;

