import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react";
import { Link, Redirect } from "react-router-dom";

import SessionContextProvider from "./SessionContextProvider";
import SessionPageSwitch from "./SessionPageSwitch";

const SessionPageRouteComponent = (props) => {
    const sessionId = props.match.params.sessionId;

    return (
        <SessionContextProvider sessionId={sessionId}>
            <SessionPageSwitch/>
        </SessionContextProvider>
    );
};

export default SessionPageRouteComponent;