import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "../component/Header";

import InviteFriendsFragment from "../fragment/lobby/InviteFriendsFragment";
import LobbyFragment from "../fragment/lobby/LobbyFragment";
import SettingsFragment from "../fragment/lobby/SettingsFragment";

const LobbyPage = (props) => {

    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("title.lobby")}</title>
            </Helmet>
            <div className={"container-fluid pt-3"}>
                <Header pageTitle={t("lobby")}/>
                <div className={"row"}>
                    <div className={"col-xl pb-3"}>
                        <div className={"container-outside"}>
                            <SettingsFragment/>
                        </div>
                    </div>
                    <div className={"col-xl pb-3"}>
                        <div className={"container-center"}>
                            <LobbyFragment/>
                        </div>
                    </div>
                    <div className={"col-xl pb-3"}>
                        <div className={"container-outside"}>
                            <InviteFriendsFragment/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LobbyPage;