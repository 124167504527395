import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";


import SessionContext from "../context/SessionContext";

import * as DiceRolls from "../util/DiceRolls";


const AnnounceDiceInput = () => {
    const { t } = useTranslation();
    const { session, dispatch, sessionClient } = useContext(SessionContext);
    const [ announced, setAnnounced ] = useState(false);
    const [ invalid, setInvalid ] = useState(false);
    const inputRef = useRef(null);

    const { myDiceRoll } = session;

    const announceDiceRoll = () => {
        const diceInputValue = inputRef.current.value;

        // Validate input
        if(!validateInput()) {
            return;
        }

        // Send announcement
        let diceRollToAnnounce;

        if(diceInputValue.length == 0) {
            diceRollToAnnounce = myDiceRoll;
        } else {
            diceRollToAnnounce = {
                upperEye: diceInputValue.charAt(0),
                lowerEye: diceInputValue.charAt(1)
            };
        }

        sessionClient.publish("/announce-dice-roll", diceRollToAnnounce);
        setAnnounced(true);
    };

    const announceMyRealDiceRoll = () => {
        sessionClient.publish("/announce-dice-roll", myDiceRoll);
        setAnnounced(true);
    };

    const validateInput = () => {
        const diceInputValue = inputRef.current.value;

        const regex = /^[1-6][1-6]$/;
        const isValid = diceInputValue.length === 0 || regex.test(diceInputValue);

        setInvalid(!isValid);
        return isValid;
    }

    const onKeyPress = (e) => {
        if (event.keyCode === 13) {
            announceDiceRoll();
        }
    };

    if(announced) {
        return <div>Announced</div>
    }

    if(myDiceRoll && DiceRolls.isMia(myDiceRoll)) {
        return (
            <button
              onClick={announceMyRealDiceRoll}
              className={"btn btn-lg btn-primary"}
              type="button"
            >
              {t("announce")}
            </button>
        );
    }



    return (
        <div className={"input-group input-group-lg"}>
          <input
            ref={inputRef}
            type="text"
            className={`form-control ${invalid ? "is-invalid" : ""}`}
            placeholder={`${myDiceRoll?.upperEye || ""}${myDiceRoll?.lowerEye || ""}`}
            onBlur={validateInput}
            onKeyPress={onKeyPress}
          />
          <div className={"input-group-append"}>
            <button
              onClick={announceDiceRoll}
              className={"btn btn-lg btn-primary"}
              type="button"
            >
              {t("announce")}
            </button>
          </div>
          {
            invalid ? (
                <div className={"invalid-feedback"}>
                    {t("invalid-dice-roll")}
                </div>
            ) : null
          }


        </div>
    );
};

export default AnnounceDiceInput;