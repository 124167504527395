import i18n from "i18next";
import HttpApi from 'i18next-http-backend';
import { useTranslation, initReactI18next } from "react-i18next";


const languageRegex = /(\w+)(-.*)?/;
const detectedLanguage = languageRegex.exec(navigator.language || navigator.userLanguage)[1] || "en";

console.debug("Translation :: Detected language", detectedLanguage);

const supportedLanguages = ["en", "de"];
const fallbackLanguage = "en";
const language = supportedLanguages.indexOf(detectedLanguage) >= 0 ? detectedLanguage : fallbackLanguage;

console.debug("Translation :: Using language", language);

// Localized resources
const localizedUrl = (resource) => (`/i18n/${language}/${resource}`);

const checkResponse = (response) => {
    if(!response.ok) {
        throw new Error("Response is not ok");
    }

    return response;
};

const fetchLocalizedResource = (resource) => {
    return new Promise((resolve, reject) => {
        // Fetch language resource
        fetch(localizedUrl(resource))
            .then(checkResponse)
            .then(resolve);
    });
};

const replaceHtmlVariables = (html) => {
    return html.replace(/\{\{hostname\}\}/g, window.location.hostname)
};

const fetchLocalizedHtml = (htmlResource) => {
    return new Promise((resolve, reject) => {
        fetchLocalizedResource(htmlResource)
            .then(response => response.text())
            .then(replaceHtmlVariables)
            .then(resolve)
    });
};


// I18n
const backendOptions = {
    loadPath: '/i18n/{{lng}}/language.json'
};

const options = {
    backend: backendOptions,
    lng: language,
    fallbackLng: fallbackLanguage
};

i18n
    .use(HttpApi)
    .use(initReactI18next)
    .init(options);


export {
    i18n,
    language,
    fallbackLanguage,
    localizedUrl,
    fetchLocalizedResource,
    fetchLocalizedHtml
};