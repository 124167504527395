import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import SessionContext from "../../context/SessionContext";

const RoundsSelect = () => {
    const { t } = useTranslation();
    const { session, sessionClient } = useContext(SessionContext);
    const { myPlayer, rounds } = session;

    const onRoundsChange = (e) => {
        const rounds = parseInt(e.target.value);

        sessionClient.publish("/edit-rounds", { rounds: rounds });
    };

    return (
        <div className={"input-group input-group-sm"}>
            <select disabled={!myPlayer.administrator} value={rounds} className={"form-select form-select-sm"} onChange={onRoundsChange} id={"roundsSelect"}>
                <option value={1}>1</option>
                <option value={3}>3</option>
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={-1}>{t("infinite")}</option>
            </select>
            <label className={"input-group-text"} htmlFor={"roundsSelect"}>{t("rounds")}</label>
        </div>
    );
};

const SettingsFragment = () => {
    const { t } = useTranslation();
    const { session } = useContext(SessionContext);


    return (
        <>
            <h3 className={"text-center"}>{t("settings")}</h3>
            <RoundsSelect/>
        </>
    );
};

export default SettingsFragment;

