const toDiceRoll = (value) => {
    if(!(value instanceof String || typeof value === "string")) {
         throw `${value} is not a string`;
    }

    if(value.length !== 2) {
        throw `${value} is not a 2 character string`;
    }

    return {
        upperEye: value.charAt(0),
        lowerEye: value.charAt(1)
    };
};

const toString = (diceRoll) => {
    return `${diceRoll.upperEye}${diceRoll.lowerEye}`;
};

const list = [
    toDiceRoll("31"),
    toDiceRoll("32"),
    toDiceRoll("41"),
    toDiceRoll("42"),
    toDiceRoll("43"),
    toDiceRoll("51"),
    toDiceRoll("52"),
    toDiceRoll("53"),
    toDiceRoll("54"),
    toDiceRoll("61"),
    toDiceRoll("62"),
    toDiceRoll("63"),
    toDiceRoll("64"),
    toDiceRoll("65"),
    toDiceRoll("11"),
    toDiceRoll("22"),
    toDiceRoll("33"),
    toDiceRoll("44"),
    toDiceRoll("55"),
    toDiceRoll("66"),
    toDiceRoll("21")
];

const indexOf = (diceRoll) => {
    if(!diceRoll) {
        return -1;
    }

    for(let i = 0; i < list.length; i++) {
        const d = list[i];

        if(d.upperEye == diceRoll.upperEye && d.lowerEye == diceRoll.lowerEye) {
            return i;
        }
    }

    return -1;
};

const isMia = (diceRoll) => {
    return diceRoll.upperEye == "2" && diceRoll.lowerEye == "1";
};

const min = (diceRoll1, diceRoll2) => {
    const index1 = indexOf(diceRoll1);
    const index2 = indexOf(diceRoll2);

    return index1 < index2 ? diceRoll1 : diceRoll2;
};

const max = (diceRoll1, diceRoll2) => {
    const index1 = indexOf(diceRoll1);
    const index2 = indexOf(diceRoll2);

    return index1 > index2 ? diceRoll1 : diceRoll2;
};

export { list, indexOf, toDiceRoll, toString, isMia, min, max };
