import React, { createContext, useContext, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


import AnnouncingFragment from "../fragment/in-game/AnnouncingFragment";
import DecidingFragment from "../fragment/in-game/DecidingFragment";
import RollingFragment from "../fragment/in-game/RollingFragment";
import WaitingForAnnouncementFragment from "../fragment/in-game/WaitingForAnnouncementFragment";
import WaitingForDecisionFragment from "../fragment/in-game/WaitingForDecisionFragment";
import WaitingForRollFragment from "../fragment/in-game/WaitingForRollFragment";

// Contexts
import SessionContext from "../context/SessionContext";

const switchWaitingForTurnFragment = (state) => {
    switch(state) {
        case "Rolling":
            return (<WaitingForRollFragment/>);
        case "Announcing":
            return (<WaitingForAnnouncementFragment/>);
        case "Deciding":
            return (<WaitingForDecisionFragment/>);
        default:
            console.error("InGameFragmentSwitch :: Unknown current player state ", myPlayer.state);
            return null;
    }
};

const InGameFragmentSwitch = () => {

    const { session, dispatch } = useContext(SessionContext);
    const { myPlayer, currentPlayer } = session;

    switch(myPlayer.state) {
        case "Rolling":
            return (<RollingFragment/>);
        case "Announcing":
            return (<AnnouncingFragment/>);
        case "Deciding":
            return (<DecidingFragment/>);
        case "WaitingForTurn":
            return switchWaitingForTurnFragment(currentPlayer.state);
        default:
            console.error("InGameFragmentSwitch :: Unknown state ", myPlayer.state);
            return null;
    }
};

export default InGameFragmentSwitch;