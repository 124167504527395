import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";


import SessionContext from "../context/SessionContext";

// Components
import Header from "../component/Header";
import CountDown from "../component/CountDown";

import GameResultsFragment from "../fragment/game-results/GameResultsFragment";

const GameResultsPage = (props) => {

    const { t } = useTranslation();
    const { session } = useContext(SessionContext);
    const { myPlayer, nextTransitionDate, players } = session;

    const leaderBordPlayers = [...players];
    leaderBordPlayers.sort((a, b) => (a.losses - b.losses));

    const listPlayers = leaderBordPlayers.map(
        (player, index) => (
            <li key={player.id} className={"list-group-item"}>
                {index === 0 ? "🥇" : null}
                {index === 1 ? "🥈" : null}
                {index === 2 ? "🥉" : null}
                {player.name}
                {player.id === session.myPlayer.id ? <span className={"ms-1 badge bg-primary"}>{t("me")}</span> : null}
                {`${player.losses} 💀`}
            </li>
        )
    );

    return (
        <>
            <Helmet>
                <title>{t("title.game-results")}</title>
            </Helmet>
            <div className={"container-fluid pt-3"}>
                <Header pageTitle={t("game-results")}/>
                <div className={"row"}>
                    <div className={"col-xl pb-3"}>
                        <div className={"container-outside"}>
                        </div>
                    </div>
                    <div className={"col-xl pb-3"}>
                        <div className={"container-center"}>
                            <GameResultsFragment/>
                        </div>
                    </div>
                    <div className={"col-xl pb-3"}>
                        <div className={"container-outside"}>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default GameResultsPage;