import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import { i18n } from "./Translation";
import { installServiceWorker } from "./ServiceWorker";

import Spinner from "./component/Spinner";
import App from "./App";

const Fallback = () => (
    <div className={"d-flex justify-content-center pt-3"}>
        <Spinner/>
    </div>
);

const Main = () => (
    <Suspense fallback={<Fallback/>}>
        <App />
    </Suspense>
);

ReactDOM.render(<Main/>, document.querySelector("#root"));

// installServiceWorker();