import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getValue, setValue } from "../util/LocalStorage";
import { installServiceWorker } from "../ServiceWorker";

const UpdateToast = () => {

    const { t } = useTranslation();
    const toastRef = useRef(null);
    let bootstrapToast;

    useEffect(() => {
        const options = {
            autohide: false
        };

        bootstrapToast = new bootstrap.Toast(toastRef.current, options);
        bootstrapToast.show();
    }, []);

    const refreshPage = () => {
        location.reload(true);
    };

    return (
        <div ref={toastRef} className={"toast cookie-consent"}>
            <div className={"toast-header"}>
                <strong className={"me-auto"}>{t("service-worker.title")}</strong>
                <button type="button" className={"btn-close"} data-bs-dismiss="toast"></button>
            </div>
            <div className={"toast-body d-flex"}>
                <div>
                    {t("service-worker.content")}
                </div>
                <div className="ms-auto">
                    <button type="button" className={"btn btn-primary"} data-bs-dismiss="toast" onClick={refreshPage}>{t("refresh")}</button>
                </div>
            </div>
        </div>
    );
}

const ServiceWorkerInstaller = () => {

    const [ showUpdateToast, setShowUpdateToast ] = useState(false);

    useEffect(() => {
        installServiceWorker();

        // Service worker skips the waiting phase ("self.skipWaiting()")
        // Therefore we do not have to show a refresh page
        // installServiceWorker({
        //      onUpdateAvailable: () => setShowUpdateToast(true)
        // });
    }, []);

    if(showUpdateToast) {
        return (
            <>
                <UpdateToast/>
            </>
        );
    }

    return null;
};

export default ServiceWorkerInstaller;