import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


// Contexts
import SessionContext from "../../context/SessionContext";

// Components
import CountDown from "../../component/CountDown";

const MiaRoller = () => {
    const { t } = useTranslation();
    const { session } = useContext(SessionContext);
    const miaRoller = session.miaRoller;

    return (
        <ul className={"list-group"}>
            <li className={"list-group-item d-flex justify-content-between"}>
                <span>
                    { miaRoller.name }
                    { miaRoller.id === session.myPlayer.id ? <span className={"ms-1 badge bg-primary"}>{t("me")}</span> : null }
                </span>
                <span>
                    {`${miaRoller.mias} 🎲`}
                </span>
            </li>
        </ul>
    );
};

const LoserList = () => {
    const { t } = useTranslation();
    const { session } = useContext(SessionContext);

    const loserItems = session.losers.map(
        (player) => (
            <li key={player.id} className={"list-group-item d-flex justify-content-between"}>
                <span>
                    { player.name }
                    { player.id === session.myPlayer.id ? <span className={"ms-1 badge bg-primary"}>{t("me")}</span> : null }
                </span>
                <span>
                    {`${player.losses} 💀`}
                </span>
            </li>
        )
    );

    return (
        <ul className={"list-group"}>
            { loserItems }
        </ul>
    );
};

const RoundResultsFragment = () => {

    const { t } = useTranslation();
    const { session, dispatch } = useContext(SessionContext);
    const { myPlayer, nextTransitionDate } = session;

    return (
        <>
            {
                session.miaRoller ? (
                    <div className={"pb-3"}>
                        <h3 className={"text-center"}>{t("mia-roller")}</h3>
                        <div className={"d-grid gap-3"}>
                            <MiaRoller/>
                        </div>
                    </div>
                ) : null
            }
            <div className={"pb-3"}>
                <h3 className={"text-center"}>{t("losers")}</h3>
                <div className={"d-grid gap-3"}>
                    <LoserList/>
                </div>
            </div>
            <div className={"d-flex justify-content-center"}>
                <span>{t("game-progresses-in")}</span>&nbsp;
                <b><CountDown targetDate={nextTransitionDate}/></b>&nbsp;
                <span>{t("seconds")}</span>
            </div>
        </>
    );
};

export default RoundResultsFragment;