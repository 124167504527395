const checkResponse = (response) => {
    if(!response.ok) {
        throw new Error("Response is not ok");
    }

    return response;
};

const parseResponse = (response) => {
    return response.json()
};


const createSession = () => {
    return fetch('/api/v1/session/create', {
        method: 'POST'
    })
    .then(checkResponse)
    .then(response => response.json());
}

const getSession = (sessionId) => {
    return fetch(`/api/v1/session/${sessionId}`)
    .then(checkResponse)
    .then(response => response.json());
}


export {
    createSession,
    getSession
};