import React, { useEffect } from 'react';

import { Shake } from "shake.js";

const useShakeListener = (onShake) => {

    const registerListener = () => {
        window.addEventListener('shake', onShake, false);
    };

    const unregisterListener = () => {
        window.removeEventListener('shake', onShake, false);
    };

    useEffect(() => {
        registerListener();

        return unregisterListener;
    });
}

export { useShakeListener };