import React from 'react';

const ToastContainer = ({children}) => {
    return (
        <div className={"toast-container position-absolute bottom-0 start-50 translate-middle-x pb-3"}>
            {children}
        </div>
    );
}

export default ToastContainer;