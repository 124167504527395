import React, { createContext, useContext, useEffect, useMemo, useReducer } from "react";

import SessionContext from "../context/SessionContext";

import SessionClient from "../SessionClient";


import { reducer as sessionReducer } from "../reducer/SessionReducer";

const createOnSessionJoin = (sessionClient, dispatch) => {
    return (response) => {
        dispatch({
            type: "update",
            session: response
        });

        // Subscribe to session updates
        sessionClient.subscribe("/update", (response) => {
            dispatch({
                type: "update",
                session: response
            });
        });
    }
};

const createOnConnect = (sessionClient, dispatch) => {
    return () => {
        console.debug("SessionClient :: Connected");
        const onSessionJoin = createOnSessionJoin(sessionClient, dispatch);
        sessionClient.request("/join", onSessionJoin);
    };
};


const SessionContextProvider = ({sessionId, children}) => {

    const [session, dispatch] = useReducer(sessionReducer, {});
    const sessionClient = useMemo(() => new SessionClient(sessionId), [sessionId]);


    useEffect(
        () => {
            // Activate on mount
            const onConnect = createOnConnect(sessionClient, dispatch);
            sessionClient.activate(onConnect);

            // Cleanup on unmount
            return () => {
                // Deactivate session client
                sessionClient.deactivate();
            };
        },
        []
    );

    const sessionContextValue = useMemo(() => ({
        session,
        dispatch,
        sessionClient
    }), [session, dispatch, sessionClient]);

    return (
        <SessionContext.Provider value={sessionContextValue}>
            {children}
        </SessionContext.Provider>
    )

}

export default SessionContextProvider;