import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


// Components
import DiceRollResult from "../../component/DiceRollResult";

// Contexts
import SessionContext from "../../context/SessionContext";

const DecidingFragment = () => {

    const { t } = useTranslation();
    const { session, dispatch, sessionClient } = useContext(SessionContext);
    const { myPlayer, priorPlayer } = session;
    const [ decided, setDecided ] = useState(false);

    const acceptDiceRoll = () => {
        setDecided(true);

        sessionClient.publish("/accept-dice-roll");
    };

    const rejectDiceRoll = () => {
        setDecided(true);

        sessionClient.publish("/reject-dice-roll");
    };

    return (
        <>
            <h3 className={"text-center"}>{t("decide")}</h3>
            <h1 className={"text-center mt-5"}>{t("player-x-dice-roll", { player: priorPlayer.name })}</h1>
            <div className={"mb-5"}>
                <DiceRollResult diceRoll={session.priorPlayer?.announcedDiceRoll}/>
            </div>
            <div className={"d-grid gap-3"}>
                <button
                  className={`btn btn-lg ${decided ? "btn-secondary" : "btn-primary"}`}
                  disabled={decided}
                  onClick={acceptDiceRoll}
                >
                  {t("accept")}
                </button>
                <button
                  className={`btn btn-lg ${decided ? "btn-secondary" : "btn-danger"}`}
                  disabled={decided}
                  onClick={rejectDiceRoll}
                >
                  {t("reject")}
                </button>
            </div>
        </>
    );
};

export default DecidingFragment;