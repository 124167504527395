import React, { useContext, useEffect, useState, useRef } from 'react';


const CountDown = ({targetDate}) => {

    const effectiveTargetDate = (typeof targetDate === "string") ? new Date(targetDate) : targetDate;

    const calculateSecondsLeft = () => {
         const now = new Date();
         const milliSecondsLeft = effectiveTargetDate.getTime() - now.getTime();

         if(milliSecondsLeft < 0) {
            return 0;
         }

         return Math.ceil(milliSecondsLeft / 1000);
    };

    // Set initial state
    const [secondsLeft, setSecondsLeft] = useState(calculateSecondsLeft());

    // Count down loop
    let timeout;

    const loop = () => {
        const calculatedSecondsLeft = calculateSecondsLeft();
        setSecondsLeft(calculatedSecondsLeft);

        if(calculatedSecondsLeft > 0) {
            timeout = setTimeout(loop, 1000);
        }
    };

    // Begin loop in 1 second
    timeout = setTimeout(loop, 1000);

    const cleanUp = () => {
        clearTimeout(timeout);
    };

    useEffect(() => cleanUp);

    return (<>{secondsLeft}</>);
};

export default CountDown;