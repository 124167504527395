import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import SessionContext from "../context/SessionContext";

import Header from "../component/Header";

import RoundResultsFragment from "../fragment/round-results/RoundResultsFragment";
import PlayerListFragment from "../fragment/in-game/PlayerListFragment";

const RoundResultsPage = (props) => {

    const { t } = useTranslation();
    const { session } = useContext(SessionContext);

    return (
        <>
            <Helmet>
                <title>{t("title.round-results")}</title>
            </Helmet>
            <div className={"container-fluid pt-3"}>
                <Header pageTitle={t("round-x-results", { round: session.round })}/>
                <div className={"row"}>
                    <div className={"col-xl pb-3"}>
                        <div className={"container-outside"}>
                        </div>
                    </div>
                    <div className={"col-xl pb-3"}>
                        <div className={"container-center"}>
                            <RoundResultsFragment/>
                        </div>
                    </div>
                    <div className={"col-xl pb-3"}>
                        <div className={"container-outside"}>
                            <PlayerListFragment/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RoundResultsPage;