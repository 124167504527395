import React from 'react';

import Logo from "./Logo";

const Header = ({pageTitle}) => {
    return (
        <div className={"row align-items-center"}>
            <div className={"col"}>
                <Logo/>
            </div>
            <div className={"col"}>
                <h2 className={"text-center"}>{pageTitle}</h2>
            </div>
            <div className={"col"}></div>
        </div>
    );
}

export default Header;