import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


// Contexts
import SessionContext from "../../context/SessionContext";

const PlayerItem = ({player}) => {
    const { t } = useTranslation();
    const { session, dispatch, sessionClient } = useContext(SessionContext);
    const [ editing, setEditing ] = useState(false);
    const inputRef = useRef(null);

    const myPlayer = session.myPlayer;

    const editPlayerName = () => {
        setEditing(true);
    };

    const savePlayerName = () => {
        sessionClient.publish("/edit-player-name", { name: inputRef.current.value });
        setEditing(false);
    };

    const onKeyPress = (e) => {
        console.debug(e);

        if (event.keyCode === 13) {
            savePlayerName();
        }
    };

    const cancel = () => {
        setEditing(false);
    };

    if(editing) {
        return (
            <li className={`list-group-item`}>
                <div className={"input-group"}>
                    <input onKeyPress={onKeyPress} ref={inputRef} type="text" className={"form-control"} defaultValue={player.name} placeholder={t("player-name")}/>
                    <button className={"btn btn-primary"} type="button" onClick={savePlayerName}>{t("save")}</button>
                    <button className={"btn btn-danger"} type="button" onClick={cancel}>{t("cancel")}</button>
                </div>
            </li>
        );
    } else {
        const onReadyChange = () => {
            sessionClient.publish("/ready", !session.myPlayer.ready);
        };

        const readyCheckbox = (
            <div className={"form-check form-switch d-flex align-items-center"}>
                <input className={"form-check-input"} type="checkbox" onChange={onReadyChange} checked={session.myPlayer.ready} id={"readyCheckbox"}/>
                <label className={"form-check-label ms-2 align-middle"} htmlFor={"readyCheckbox"}>
                  {t("ready")}
                </label>
            </div>
        );

        return (
            <li className={`list-group-item ${player.ready ? "list-group-item-success" : ""}`}>
                <div className={"d-flex justify-content-between"}>
                    <span>
                        {player.name}
                        {player.id === session.myPlayer.id ? <span className={"badge cursor-pointer"} onClick={editPlayerName}>✍️</span> : null}
                        {player.id === session.myPlayer.id ? <span className={"ms-1 badge bg-primary"}>{t("me")}</span> : null}
                        {player.administrator ? <span className={"ms-1 badge bg-warning"}>👑</span> : null}
                    </span>
                    {player.id === myPlayer.id ? readyCheckbox : null}
                </div>
            </li>
        );
    }
};

const LobbyFragment = () => {

    const { t } = useTranslation();
    const { session, dispatch } = useContext(SessionContext);

    const listPlayers = session.players.map(
        (player) => (<PlayerItem key={player.id} player={player}/>)
    );

    return (
        <>
            <h3 className={"text-center"}>{t("players")}</h3>
            <div className={"d-grid gap-3"}>
                <ul className={"list-group"}>
                    { listPlayers }
                </ul>
            </div>

        </>
    );
};

export default LobbyFragment;