import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import Header from "../component/Header";
import LocalizedHtml from "../component/LocalizedHtml";

const PrivacyPolicyPage = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t("title.terms-of-service")}</title>
            </Helmet>
            <div className={"container-fluid pt-3"}>
                <Header pageTitle={t("terms-of-service")}/>
                <div className={"container-center"}>
                    <LocalizedHtml resource={"terms-of-service.html"}/>
                </div>
            </div>
        </>
    );
};

export default PrivacyPolicyPage;