import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from "react-i18next";

// Contexts
import SessionContext from "../context/SessionContext";

// Sound
import { playSound } from "../util/Sound";

const DiceCup = ({initialAnimationClass, initialAnimationDuration}) => {

    const {sessionClient} = useContext(SessionContext);
    const [animationClass, setAnimationClass] = useState("");

    let timeoutId = null;

    const clearAnimation = () => {
        setAnimationClass("");

        if(timeoutId) {
            clearTimeout(timeoutId);
            timeoutId = null;
        }
    }

    const runAnimation = (animationClass, duration) => {
        clearAnimation();

        setAnimationClass(animationClass);

        // Clear animation after the given duration
        timeoutId = setTimeout(clearAnimation, duration);
    };

    // Stomp subscriptions
    useEffect(() => {

        const shakeSubscription = sessionClient.subscribe("/dice-cup-shake", () => {
            runAnimation("dice-cup-shake-animation", 250);
            playSound("dice-shake-1.mp3");
        });

        return () => {
            shakeSubscription.unsubscribe();

            clearAnimation();
        };
    }, []);

    // Initial animation
    if(initialAnimationClass && initialAnimationDuration) {
        useEffect(() => {
            runAnimation(initialAnimationClass, initialAnimationDuration);
        }, []);
    }

    return (
        <>
            <img
                src={"/image/dice-cup-2.png"}
                className={`dice-cup ${animationClass}`}
            />
        </>
    );
};

export default DiceCup;