
const installServiceWorker = (options) => {

    const {onRegistration, onUpdateAvailable} = options || {};

    if ("serviceWorker" in navigator) {
        //console.debug("Service Worker :: Adding load event listener");

        //window.addEventListener('load', () => {
            console.debug("Service Worker :: Registering service worker");
            navigator.serviceWorker.register('/service-worker.js')
                .then(registration => {
                    console.debug("Service Worker :: Registered service worker", registration);

                    if(onRegistration) {
                        onRegistration();
                    }

                    // An update is available, if there's a waiting service worker
                    if(registration.waiting) {
                        onUpdateAvailable();
                    }

                    // An update is available, if there's an updated service worker found and installed
                    registration.onupdatefound = () => {
                        const installingWorker = registration.installing;

                        console.debug("Service Worker :: Update found", installingWorker);

                        installingWorker.onstatechange = () => {
                            switch (installingWorker.state) {
                                case 'installed':
                                    if (navigator.serviceWorker.controller) {
                                        console.debug("Service Worker :: Update installed");

                                        if(onUpdateAvailable) {
                                            onUpdateAvailable();
                                        }
                                    } else {
                                        console.debug("Service Worker :: Update not installed");
                                    }
                                    break;
                            }
                        };
                    }
                });
        //});
    }
};

export { installServiceWorker }