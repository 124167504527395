import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import { fetchLocalizedHtml } from "../Translation";

const LocalizedHtml = ({resource}) => {
    const { t } = useTranslation();
    const [ html, setHtml ] = useState("");

    const fetchHtmlResource = () => {
        fetchLocalizedHtml(resource)
            .then(setHtml)
            .catch(error => {
                console.error("LocalizedHtml :: Failed to fetch resource", resource, error);
                setHtml(t("an-unexpected-error-occurred"));
            });
    };

    // Fetch imprint only once
    useEffect(fetchHtmlResource, []);

    const markup = {
        __html: html
    };

    return (
        <>
            <div dangerouslySetInnerHTML={markup}/>
        </>
    );
};

export default LocalizedHtml;