import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


// Components
import DiceRollResult from "../../component/DiceRollResult";
import ShakeableDiceCup from "../../component/ShakeableDiceCup";
import ShakeEventProvider from "../../component/ShakeEventProvider";

// Contexts
import SessionContext from "../../context/SessionContext";

// Sound
import { playSound } from "../../util/Sound";

const RollingFragment = () => {

    const { t } = useTranslation();
    const { session, dispatch, sessionClient } = useContext(SessionContext);
    const [ shaken, setShaken ] = useState(false);
    const [ lifted, setLifted ] = useState(false);
    const { myPlayer, priorPlayer } = session;

    const onClick = () => {
        if(!lifted) {
            setLifted(true);

            sessionClient.request("/roll-dice", (diceRoll) => {
                dispatch({type: "setDiceRoll", diceRoll });
            });
        }
    };

    const onShake = () => {
        sessionClient.publish("/dice-cup-shake");
        playSound("dice-shake-1.mp3");
        setShaken(true);
    };

    return (
        <>
            <h3 className={"text-center card-title"}>{t("roll")}</h3>
            <div className={"d-flex justify-content-center align-items-center dice-cup-pane"}>
                <ShakeEventProvider threshold={7.5} timeout={100}>
                    <ShakeableDiceCup onShake={onShake}/>
                </ShakeEventProvider>
            </div>
            { !shaken ? (<p className={"text-muted text-center"}>{t("drag-the-dice-cup-to-shake")}</p>) : null }
            <div className={"d-grid gap-3"}>
                <button
                    onClick={onClick}
                    className={`btn btn-lg ${shaken ? "btn-primary" : "btn-secondary"}`}
                    type="button"
                    disabled={!shaken || lifted}
                >
                    {shaken ? t("lift") : t("shake-once-to-lift")}
                </button>
            </div>
        </>
    );
};

export default RollingFragment;