import React, { createContext, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";

import SessionContext from "../context/SessionContext";

import * as DiceRolls from "../util/DiceRolls";

const QuickLieButton = ({diceRoll}) => {

    const { t } = useTranslation();
    const { sessionClient } = useContext(SessionContext);

    const announceDiceRoll = () => {
        sessionClient.publish("/announce-dice-roll", diceRoll);
    };

    return (
        <button type="button" className={"btn btn-sm btn-outline-warning"} onClick={announceDiceRoll}>
            <span className={"h5"}>{diceRoll.upperEye}{diceRoll.lowerEye}</span>
        </button>
    );
};

export default QuickLieButton;