import React, { useEffect } from 'react';

import Shake from "shake.js";

const ShakeEventProvider = ({children, threshold=10, timeout=1000}) => {

    let shake = null;

    const registerProvider = () => {
        shake = new Shake({
            threshold: threshold,
            timeout: timeout
        });

        shake.start();

        console.debug("Shake Event Provider :: Registered", shake);
    };

    const unregisterProvider = () => {
        shake.stop();

        console.debug("Shake Event Provider :: Unregistered", shake);
    };

    useEffect(() => {
        registerProvider();

        return unregisterProvider;
    });

    return (<>{children}</>);
}

export default ShakeEventProvider;