
const setValue = (key, value) => {
    console.debug("LocalStorage :: Setting value", key, value);
    localStorage.setItem(key, JSON.stringify(value));
};

const getValue = (key, defaultValue) => {
    const storedItem = localStorage.getItem(key);

    if(storedItem) {
        try {
            return JSON.parse(storedItem);
        } catch (error) {
            console.error("LocalStorage :: Failed to deserialize stored item", error, storedItem);
        }
    }

    return defaultValue;
};

export { getValue, setValue };