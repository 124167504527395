import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NavBar = () => {

    const { t } = useTranslation();

    return (
        <nav className={"navbar navbar-expand-lg navbar-light bg-light"}>
          <div className={"container-fluid"}>
            <Link className={"navbar-brand"} to="/">{t("app.title")}</Link>
            <button className={"navbar-toggler"} type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
              <span className={"navbar-toggler-icon"}></span>
            </button>
            <div className={"collapse navbar-collapse"} id="navbarSupportedContent">
              <ul className={"navbar-nav me-auto mb-2 mb-lg-0"}>
                <li className={"nav-item"}>
                  <Link className={"nav-link"} to="/">{t("home")}</Link>
                </li>
                <li className={"nav-item"}>
                  <Link className={"nav-link"} to="/donate">{t("donate")}</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
    );
};

export default NavBar;